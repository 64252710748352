import axios from 'axios';
import { AlertValue, Factors, Login, User } from './interfaces';

const api_endpoint = process.env.REACT_APP_BACK_URL || '';

export const login = ({ username, password }: Login) =>
  axios
    .post(
      `${api_endpoint}/api/login`,
      {
        username,
        password
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((res) => {
      return res.data as User;
    })
    .catch((err) => {
      throw new Error(err.message);
    });

export const updateOnline = (value: string, online: boolean) =>
  axios
    .post(
      `${api_endpoint}/api/period-all`,
      { target: value, online },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });

export const updateDates = ({ target, days }: any) =>
  axios
    .post(
      `${api_endpoint}/api/period`,
      {
        target,
        days
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });

export const getLatestStatusOf = (id: string) =>
  axios
    .get(`${api_endpoint}/api/latest/${id}`)
    .then((res) => {
      return res.data as AlertValue;
    })
    .catch((err) => {
      throw new Error(err);
    });

export const getLatestAlerts = (unique: boolean, page: number, limit: number, key: string | undefined) =>
  axios
    .get(`${api_endpoint}/api/latest-alerts?page=${page}&limit=${limit}&unique=${unique}${key ? `&key=${key}` : ``}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });

export const updateFactors = ({ factors }: { factors: Factors }) =>
  axios
    .post(
      `${api_endpoint}/api/factors`,
      {
        factors
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });

export const getFactors = () =>
  axios
    .get(`${api_endpoint}/api/factors`)
    .then((res) => {
      console.log('res', res);
      return res.data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });

export const getAdditionalDataByAlertId = (alertId: string, periodId: string) =>
  axios
    .get(`${api_endpoint}/api/alerts/${alertId}/additionalData${periodId ? `?period_id=${periodId}` : ``}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });

export const removeOldHistory = () =>
  axios
    .delete(`${api_endpoint}/api/old-history`)
    .then((res) => {
      return res.status;
    })
    .catch((err) => {
      throw new Error(err);
    });