import * as React from 'react';
import Title from './Title';
import { removeOldHistory } from '../RestApi';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Alert, FormControl, } from '@mui/material';

export default function DeleteHistory() {
  const [success, setSuccess] = React.useState(false);

  const [error, setError] = React.useState(false);

  const handleRemoval = (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    removeOldHistory()
      .then((res) => {
        setSuccess(true);
        setError(false);
      })
      .catch((err) => {
        setError(true);
        setSuccess(false);
      });
  };

  return (
    <React.Fragment>
      {success && <Alert severity="success">{success}</Alert>}
      {error && <Alert severity="error">Delete failed</Alert>}
      <Title>Poista 7 päivää vanhempi historia</Title>
        <Button onClick={handleRemoval} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Poista vanha historia
        </Button>
    </React.Fragment>
  );
}
